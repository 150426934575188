<template>
  <div class="px-4">
    <v-form class="mt-3">
      <v-card class="pa-4 mx-auto">
        <h2 class="secondary pa-3">FINANCE FORM</h2>

        <v-row>
          <v-col cols="12">
            <h3 class="success--text mt-3 pl-2">Financial Management</h3>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <b>Withdrawal application submitted:</b>
            <v-text-field
              v-model.number="dform.WithdrawalApplication"
              type="number"
              outlined
              dense
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <b>Replenishment Application submitted:</b>
            <v-text-field
              v-model.number="dform.ReplenishmentApplication"
              type="number"
              outlined
              dense
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <b>Replenishment received:</b>
            <v-text-field
              v-model.number="dform.ReplenishmentReceived"
              type="number"
              outlined
              dense
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <h3 class="success--text mt-3 pl-2">Income</h3>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <b>Amount replenished:</b>
            <v-text-field
              placeholder="NGN"
              v-model.number="dform.AmountReplenished"
              type="number"
              outlined
              dense
              @change="processTotalIncome"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <b>Government Contribution:</b>
            <v-text-field
              placeholder="NGN"
              v-model.number="dform.GovtContribution"
              type="number"
              outlined
              dense
              @change="processTotalIncome"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <b>Interest:</b>
            <v-text-field
              placeholder="NGN"
              v-model.number="dform.Interest"
              type="number"
              outlined
              dense
              @change="processTotalIncome"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <b>Income Total:</b>
            <v-text-field
              placeholder="NGN"
              v-model.number="dform.TotalIncome"
              type="number"
              outlined
              dense
              readonly
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <h3 class="success--text mt-3 pl-2">Expenditure</h3>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <b>Goods:</b>
            <v-text-field
              placeholder="NGN"
              v-model.number="dform.Goods"
              type="number"
              outlined
              dense
              @change="processTotalExpenditure"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <b>Non-Consulting:</b>
            <v-text-field
              placeholder="NGN"
              v-model.number="dform.NonConsulting"
              type="number"
              outlined
              dense
              @change="processTotalExpenditure"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <b>Consultancy:</b>
            <v-text-field
              placeholder="NGN"
              v-model.number="dform.Consultancy"
              type="number"
              outlined
              @change="processTotalExpenditure"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <b>Operating Cost:</b>
            <v-text-field
              placeholder="NGN"
              v-model.number="dform.OperatingCost"
              type="number"
              outlined
              dense
              @change="processTotalExpenditure"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <b>Training:</b>
            <v-text-field
              placeholder="NGN"
              v-model.number="dform.Training"
              type="number"
              outlined
              dense
              @change="processTotalExpenditure"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <b>Others:</b>
            <v-text-field
              placeholder="NGN"
              v-model.number="dform.Others"
              type="number"
              outlined
              dense
              @change="processTotalExpenditure"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <b>Grants - CDP:</b>
            <v-text-field
              placeholder="NGN"
              v-model.number="dform.GrantsCdp"
              type="number"
              outlined
              dense
              @change="processTotalExpenditure"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <b>Grants - GrDP:</b>
            <v-text-field
              placeholder="NGN"
              v-model.number="dform.GrantsGdp"
              type="number"
              outlined
              dense
              @change="processTotalExpenditure"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <b>Expenditure Total:</b>
            <v-text-field
              placeholder="NGN"
              v-model.number="dform.TotalExpenditure"
              type="number"
              outlined
              dense
              readonly
            >
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <h3 class="success--text mt-3 pl-2">Account Balance</h3>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <b>Balance in IDA Replenished A/C :</b>
            <v-text-field
              placeholder="NGN"
              v-model.number="dform.BalanceIda"
              type="number"
              outlined
              dense
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <b>Balance in Govt. Contribution A/C :</b>
            <v-text-field
              placeholder="NGN"
              v-model.number="dform.BalanceGovt"
              type="number"
              outlined
              dense
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <b>Balance in Interest Account:</b>
            <v-text-field
              placeholder="NGN"
              v-model.number="dform.BalanceInterest"
              type="number"
              outlined
              dense
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <b>Date of Input:</b>
            <v-text-field v-model="dform.InputDate" type="date" outlined dense>
            </v-text-field>
          </v-col>
        </v-row>

        <v-row justify="end">
          <p v-if="dform.id" class="text-end">
            <v-btn :loading="loading" @click="saveChanges" color="success"
              >Save Changes</v-btn
            >
          </p>
          <p v-else class="text-end">
            <v-btn @click="post" :loading="loading" color="success"
              >Submit</v-btn
            >
          </p>
        </v-row>
      </v-card>
    </v-form>

    <v-snackbar v-model="snackbar" bottom :color="snackColor">
      <p>{{ snackMsg }}</p>
      <template v-slot:action="{ attrs }">
        <v-btn dark v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { apiClient } from "@/services";
import { snackMixin, editUserMixin } from "@/mixins";
// import { mapState, mapGetters } from "vuex";
import { mapGetters } from "vuex";
export default {
  name: "Finance",
  mixins: [snackMixin, editUserMixin],

  data() {
    return {
      dform: this.initializeForm(),
    };
  },

   computed: {
    
    ...mapGetters(["getCurUser"]),
  },

  methods: {
    initializeForm() {
      return {
        id: "",
        WithdrawalApplication: 0,
        ReplenishmentApplication: 0,
        ReplenishmentReceived: 0,
        AmountReplenished: "",
        GovtContribution: "",
        Interest: "",
        TotalIncome: "",
        Goods: "",
        NonConsulting: "",
        Consultancy: "",
        OperatingCost: "",
        Training: "",
        Others: "",
        GrantsCdp: "",
        GrantsGdp: "",
        TotalExpenditure: "",
        BalanceIda: "",
        BalanceGovt: "",
        BalanceInterest: "",
        InputDate:"",
        created_by: "",
        modified_by: "",
      };
    },

    processTotalIncome(){
      let { AmountReplenished, GovtContribution, Interest } = this.dform
      this.dform.TotalIncome = +AmountReplenished + +GovtContribution + +Interest
    },

     processTotalExpenditure(){
      let { Goods, NonConsulting, Consultancy, OperatingCost, Training, Others, GrantsCdp, GrantsGdp } = this.dform
      this.dform.TotalExpenditure = (+Goods + +NonConsulting) + (+Consultancy + +OperatingCost) + (+Training + +Others + +GrantsCdp + +GrantsGdp)
    },

    post() {
      this.loading = true;
      this.dform.created_by = this.dform.modified_by = this.getCurUser;
      apiClient
        .post("/finance", this.dform)
        .then((res) => {
          if (res.data.ok) {
            this.dform = res.data;
            this.dform = this.initializeForm();
            this.loading = false;
            this.displayMsg("New finance created successfully");
          } else {
            this.displayMsg(res.data, "error");
          }
        })
        .catch((err) => {
          this.displayMsg(err.message, "error");
        });
    },

    
    saveChanges() {
      this.dform.type = "finance";
      this.loading = true;
      this.dform.modified_by = this.getCurUser;
      apiClient
        .put("/finance", this.dform)
        .then((res) => {
          if (res.data.ok) {
            this.displayMsg("Changes saved successfully.Redirecting ...");
            this.loading = false;
            setTimeout(() => {
              this.$router.push({
                name: "FinanceTable",
              });
            }, 3000);
          } else {
            this.displayMsg(res.data, "error");
          }
        })
        .catch((err) => {
          this.displayMsg(err.message, "error");
          this.loading = false;
        });
    },
  },
};
</script>
